.alert-block{
    max-width: 540px;
    width: 80%;
    height: 170px;
    background-color: #6a0000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px double #0f1316;
    border-radius: 5px;
    color: #0f1316;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    animation: show 1s ease-in-out;
}

@keyframes show {
    from {
        opacity: 0;
        transform: perspective(500px) translate( -50%, -150%) rotateX(45deg);
    }
    to{

        opacity: 1;
        transform: perspective(500px) translate(-50%, -50%) rotateX(0);
    }
}
.alert-btn{
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #0f1316;
    color: white;
}
.alert-btn:hover{

    color: #6a0000;
}