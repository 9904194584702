body{
  background-image: url("../public/soccer.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}
#root{
  position: relative;
}
.team-app {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  margin: 2rem auto  ;
  max-width: 800px;
  width: 800px;
  min-height: 360px;
  color: #ddd;
  background: url("../public/ac-milan.jpg") top center/ auto repeat-y;
  padding: 20px 30px;
}
.top-panel {
  margin: 1rem 0;
}
@media (max-width:1200px) {
  body{
    background-size: unset;
  }
}
@media (max-width: 800px){
  .team-app{
    position: static;
    transform: none;
  }
  .alert-block{
    position: absolute;
  }
}

